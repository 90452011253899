<template>
    <div id="profile-page">
      <h2>My Profile</h2>
      <div class="profile-info">
        <p><strong>Name:</strong> {{ profile.username }}</p>
        <p><strong>Age:</strong> {{ profile.age }}</p>
        <p><strong>Grade:</strong> {{ profile.grade }}</p>
      </div>
      <button @click="$emit('back-to-chat')">Back to Chat</button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        profile: {
          username: '',
          age: '',
          grade: ''
        }
      };
    },
    methods: {
      loadProfile() {
        const storedProfile = JSON.parse(localStorage.getItem('profile'));
        if (storedProfile) {
          this.profile = storedProfile;
        }
      }
    },
    mounted() {
      this.loadProfile();
    }
  };
  </script>
  
  <style scoped>
  #profile-page {
    text-align: center;
    margin: 20px;
  }
  
  .profile-info {
    margin-top: 10px;
  }
  
  button {
    margin-top: 20px;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }
  #profile-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.profile-icon {
  font-size: 2rem;
  color: #333;
}
  </style>
  