<template>
  <div id="main-container">
    <div id="content-container">
      <!-- Left Side: Audio Controls -->
      <div id="left-side">
        <!-- Einstein Head Section -->
        <div id="einstein-head-container">
          <img
            id="einstein-head"
            src="@/assets/albert.png"
            alt="Einstein Head"
            :class="{ speaking: isSpeaking }"
          />
        </div>
        <!-- Waveform Container -->
        <div id="waveform-container">
          <div id="waveform"></div>
          <!-- Play/Pause Button -->
          <button
            id="play-pause-button"
            @click="togglePlay"
            :disabled="!hasAudio"
            aria-label="Play/Pause Audio"
          >
            <font-awesome-icon :icon="isPlaying ? 'pause' : 'play'" />
          </button>
          <!-- Volume Slider -->
          <input
            type="range"
            id="volume-slider"
            min="0"
            max="1"
            step="0.01"
            v-model="volume"
            @input="changeVolume"
            :disabled="!hasAudio"
            aria-label="Volume Control"
          />
        </div>
      </div>

      <!-- Right Side: Profile Setup and Chat -->
      <div id="right-side">
        <div v-if="!loggedIn">
          <!-- If not logged in, ask if new or returning -->
          <div v-if="!promptingLogin">
            <div id="chat-container">
              <div id="chatbox">
                <div v-for="(message, index) in messages" :key="index" :class="message.sender === 'You' ? 'user' : 'assistant'">
                  {{ message.sender }}: {{ message.content }}
                </div>
              </div>
              <div id="input-container">
                <input
                  type="text"
                  id="input-box"
                  v-model="inputMessage"
                  @keypress.enter="handleInitialResponse"
                  placeholder="Are you a new or returning user?"
                  aria-label="Message Input"
                />
                <button id="send-button" @click="handleInitialResponse" :disabled="!canSendMessage" aria-label="Send Message">
                  Send
                </button>
              </div>
            </div>
          </div>

          <!-- Profile setup will be shown only if user chooses "new user" -->
          <AcctSetup v-if="promptingLogin === 'new'" @logged-in="handleLoggedIn" />

          <!-- Prompt for returning user's username -->
          <div v-if="promptingLogin === 'returning'">
            <div id="input-container">
              <input
                type="text"
                id="username-box"
                v-model="username"
                @keypress.enter="loginUser"
                placeholder="Please enter your username"
                aria-label="Username Input"
              />
              <button id="send-button" @click="loginUser" aria-label="Login">
                Login
              </button>
            </div>
          </div>
        </div>

        <!-- Chat will show if logged in -->
        <div v-if="loggedIn && !showProfilePage">
          <!-- Chat Container -->
          <div id="chat-container">
            <div id="chatbox">
              <div v-for="(message, index) in messages" :key="index" :class="message.sender === 'You' ? 'user' : 'assistant'">
                {{ message.sender }}: {{ message.content }}
                <font-awesome-icon
                  v-if="message.sender === 'Albert'"
                  icon="volume-up"
                  class="audio-icon"
                  @click="replayAudio(message.content)"
                  aria-label="Replay Audio"
                />
              </div>
            </div>
            <div id="input-container">
              <input
                type="text"
                id="input-box"
                v-model="inputMessage"
                @keypress.enter="sendMessage"
                placeholder="Ask Albert anything..."
                aria-label="Message Input"
              />
              <button id="send-button" @click="sendMessage" :disabled="!canSendMessage" aria-label="Send Message">
                Send
              </button>
            </div>
          </div>
        </div>

        <!-- Profile Page will show when clicked -->
        <ProfilePage v-if="showProfilePage" @back-to-chat="toggleProfilePage" />
      </div>

      <!-- Profile Icon in top right -->
      <div id="profile-toggle" @click="toggleProfilePage">
        <font-awesome-icon icon="user-circle" class="profile-icon" />
      </div>

      <!-- Light/Dark Mode Toggle -->
      <div id="theme-toggle">
        <button @click="toggleTheme" aria-label="Toggle Theme">
          <font-awesome-icon :icon="themeIcon" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// Import AcctSetup for profile setup, ProfilePage for user profile, and WaveSurfer for audio
import AcctSetup from './components/AcctSetup.vue';
import ProfilePage from './components/ProfilePage.vue'; // Import ProfilePage.vue
import WaveSurfer from 'wavesurfer.js';
import '@/assets/styles.css';

// Font Awesome for theme and profile icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSun, faMoon, faPlay, faPause, faVolumeUp, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Add icons to the library
library.add(faSun, faMoon, faPlay, faPause, faVolumeUp, faUserCircle);

export default {
  components: {
    FontAwesomeIcon,
    AcctSetup,
    ProfilePage, // Register ProfilePage component
  },
  data() {
    return {
      loggedIn: false,
      inputMessage: '',
      messages: [],
      isSpeaking: false,
      theme: localStorage.getItem('theme') || 'light-theme',
      themeIcon: 'sun', // default icon
      waveSurfer: null,
      volume: 0.5,
      isPlaying: false,
      hasAudio: false,
      profile: null, // Store profile data
      promptingLogin: false, // Track login prompt
      username: '', // Store username for returning users
      showProfilePage: false, // Track if the profile page is open
    };
  },
  computed: {
    canSendMessage() {
      return this.inputMessage.trim().length > 0;
    },
  },
  methods: {
    loadProfileFromLocalStorage() {
      const storedProfile = localStorage.getItem('profile');
      if (storedProfile) {
        this.profile = JSON.parse(storedProfile);
        if (this.profile && this.profile.username) {
          this.loggedIn = true;
          this.appendMessage(`Welcome back, ${this.profile.username}!`, 'Albert');
        }
      } else {
        this.appendMessage("Are you a new user or a returning user?", "Albert");
      }
    },
    handleInitialResponse() {
      const userResponse = this.inputMessage.toLowerCase().trim();
      if (userResponse === "new" || userResponse === "i am new") {
        this.promptingLogin = "new"; // Go to account creation
      } else if (userResponse === "returning" || userResponse === "i am returning") {
        this.promptingLogin = "returning"; // Start login process
      } else {
        this.appendMessage("Please reply with either 'new' or 'returning'.", "Albert");
      }
      this.inputMessage = '';
    },
    loginUser() {
      fetch(`https://alberttest.ddns.net/api/load_profile?username=${this.username.toLowerCase()}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('User not found');
          }
          return response.json();
        })
        .then((data) => {
          if (data.username) {
            this.profile = data;
            localStorage.setItem('profile', JSON.stringify(this.profile));
            this.loggedIn = true;
            this.appendMessage(`Welcome back, ${this.profile.username}!`, 'Albert');
          } else {
            this.appendMessage('User not found. Please try again.', 'Albert');
          }
        })
        .catch((error) => {
          console.error('Error during login:', error);
          this.appendMessage('Sorry, something went wrong. Please try again later.', 'Albert');
        });
      this.username = '';
    },
    handleLoggedIn(profileData) {
      this.profile = profileData;
      this.loggedIn = true;
      localStorage.setItem('profile', JSON.stringify(this.profile));
      this.appendMessage(`Welcome back, ${this.profile.username}!`, 'Albert');
    },
    toggleProfilePage() {
      this.showProfilePage = !this.showProfilePage; // Toggle profile page visibility
    },
    toggleTheme() {
      if (this.theme === 'light-theme') {
        this.theme = 'dark-theme';
        this.themeIcon = 'moon';
        document.body.classList.add('dark-theme');
        document.body.classList.remove('light-theme');
      } else {
        this.theme = 'light-theme';
        this.themeIcon = 'sun';
        document.body.classList.add('light-theme');
        document.body.classList.remove('dark-theme');
      }
      localStorage.setItem('theme', this.theme);
    },
    appendMessage(content, sender) {
      this.messages.push({ content, sender });
    },
    sendMessage() {
      if (!this.canSendMessage) {
        alert('Please enter a message before sending.');
        return;
      }

      this.appendMessage(this.inputMessage, 'You');

      const messagePayload = {
        message: this.inputMessage,
        profile: this.profile,  // Send profile data with the message
      };

      fetch('https://alberttest.ddns.net/api/chat', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(messagePayload),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.text) {
            this.appendMessage(data.text, 'Albert');
            this.handleAudioResponse(data.text);
          } else if (data.error) {
            this.appendMessage('Sorry, something went wrong. Please try again later.', 'Albert');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          this.appendMessage('Sorry, something went wrong. Please try again later.', 'Albert');
        });

      this.inputMessage = '';
    },

    handleAudioResponse(text) {
      fetch('https://alberttest.ddns.net/api/tts', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message: text }),
      })
        .then((response) => response.blob())
        .then((audioBlob) => {
          const audioUrl = URL.createObjectURL(audioBlob);
          this.waveSurfer.load(audioUrl);
          this.hasAudio = true;
        })
        .catch((error) => {
          console.error('Error fetching audio:', error);
        });
    },
    replayAudio(text) {
      fetch('https://alberttest.ddns.net/api/tts', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message: text }),
      })
        .then((response) => response.blob())
        .then((audioBlob) => {
          const audioUrl = URL.createObjectURL(audioBlob);
          this.waveSurfer.load(audioUrl);
          this.hasAudio = true;
        })
        .catch((error) => {
          console.error('Error replaying audio:', error);
        });
    },
    changeVolume() {
      this.waveSurfer.setVolume(this.volume);
    },
    togglePlay() {
      if (!this.hasAudio) return;

      if (this.waveSurfer.isPlaying()) {
        this.waveSurfer.pause();
      } else {
        this.waveSurfer.play();
      }
    },
  },
  mounted() {
    // Set initial theme icon
    this.themeIcon = this.theme === 'light-theme' ? 'sun' : 'moon';

    if (this.theme === 'dark-theme') {
      document.body.classList.add('dark-theme');
    } else {
      document.body.classList.add('light-theme');
    }

    // Load profile if available
    this.loadProfileFromLocalStorage();

    // Initialize WaveSurfer
    this.waveSurfer = WaveSurfer.create({
      container: '#waveform',
      waveColor: 'violet',
      progressColor: 'purple',
      cursorColor: 'transparent',
      height: 150,
      responsive: true,
    });

    // Set initial volume
    this.waveSurfer.setVolume(this.volume);

    // Event listeners for WaveSurfer
    this.waveSurfer.on('play', () => {
      this.isSpeaking = true;
      this.isPlaying = true;
    });
    this.waveSurfer.on('pause', () => {
      this.isSpeaking = false;
      this.isPlaying = false;
    });
    this.waveSurfer.on('finish', () => {
      this.isSpeaking = false;
      this.isPlaying = false;
      this.hasAudio = false;
    });
    this.waveSurfer.on('ready', () => {
      if (this.hasAudio) {
        this.waveSurfer.play();
      }
    });
  },
};
</script>

<style scoped>
#profile-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.profile-icon {
  font-size: 2rem;
  color: #333;
}
</style>
