<template>
  <div id="profile-setup">
    <h2>Create Your Profile</h2>
    <form @submit.prevent="createProfile">
      <div>
        <label for="username">Username:</label>
        <input v-model="profile.username" id="username" required />
      </div>

      <div>
        <label for="password">Password:</label>
        <input type="password" v-model="profile.password" id="password" required />
      </div>

      <div>
        <label for="gender">Gender:</label>
        <input v-model="profile.gender" id="gender" required />
      </div>

      <div>
        <label for="grade">Grade:</label>
        <input v-model="profile.grade" id="grade" required />
      </div>

      <button type="submit">Create Profile</button>
    </form>
    <p v-if="error">{{ error }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      profile: {
        username: '',
        password: '',
        gender: '',
        grade: ''
      },
      error: null
    };
  },
  methods: {
    createProfile() {
      const profilePayload = {
        username: this.profile.username,
        password: this.profile.password,
        gender: this.profile.gender,
        grade: this.profile.grade
      };

      fetch('https://alberttest.ddns.net/api/create_profile', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(profilePayload)
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to create profile');
          }
          return response.json();
        })
        .then((data) => {
          console.log('Profile created:', data);
          this.$emit('logged-in'); // Emit event to notify parent component
        })
        .catch((error) => {
          console.error(error);
          this.error = 'Failed to create profile. Please try again.';
        });
    }
  }
};
</script>
